import React, { useState, useEffect, useRef} from "react";
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import { createPortal } from 'react-dom';
import ReactPlayer from 'react-player/lazy'
import ModalTiny from "react-bootstrap/Modal"

const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return(
        createPortal(
            children,
            el
        )
    )
}


const CustomVideo = (props) => {
    const [isOpen, setOpen] = useState(false); 
    const [muted, setMuted] = useState(false); 
    const [render, setRender] = useState(false); 
     const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        }); 
    }

    const saysWho = () => {
        const { userAgent } = navigator
        let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
        let temp
      
        if (/trident/i.test(match[1])) {
          temp = /\brv[ :]+(\d+)/g.exec(userAgent) || []
      
          return `IE ${temp[1] || ''}`
        }
      
        if (match[1] === 'Chrome') {
          temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/)
      
          if (temp !== null) {
            return temp.slice(1).join(' ').replace('OPR', 'Opera')
          }
      
          temp = userAgent.match(/\b(Edg)\/(\d+)/)
      
          if (temp !== null) {
            return temp.slice(1).join(' ').replace('Edg', 'Edge (Chromium)')
          }
        }
      
        match = match[2] ? [ match[1], match[2] ] : [ navigator.appName, navigator.appVersion, '-?' ]
        temp = userAgent.match(/version\/(\d+)/i)
      
        if (temp !== null) {
          match.splice(1, 1, temp[1])
        }
      
        return match.join(' ')
      }
      
     
    useEffect(()=>{
        if(!render){
            setRender(true) 
            if(saysWho().includes('Safari')) {
                setMuted(true) 
            } else{
                setMuted(false)
            }
        } 
    })

    


    
    return(
        <>
        <strong className="video-btn" onClick={() => { setOpen(true); trackerVideo(props.title);}} onKeyDown={()=> setOpen(true)}>
            <i className="icon-video">
            </i>
        </strong>

            {isOpen &&
            
               <ModalTiny show={isOpen} onHide={() => setOpen(false)} className="player-modal">
                    <ModalTiny.Header className="contact-close-btn ">
                    <span class="close-menu" onClick={() => setOpen(false)} ><i class="close-icon"></i></span> 
                    </ModalTiny.Header>
                    <ModalTiny.Body className="event-section">
                    <ReactPlayer url={props.url} width="100%" height="100%" playing muted={muted}/>
                    </ModalTiny.Body> 
                </ModalTiny>
              
            }
        </>
    )
}

export default CustomVideo

